import React, {useEffect, useState} from 'react';
// @ts-ignore
import TelegramLogo from "../assets/telegram/telegramLogo.png";
// @ts-ignore
import TelegramIco from "../assets/telegram/telegramIco.png";
import useAuth from "../hooks/useAuth";

const CardTelegram = () => {
    const {user} = useAuth();
    const [sub1, setSub1] = useState(user?.sub1 ?? window.localStorage.getItem('sub1') ?? "clubcasino");
    const [linkTg, setLinkTg] = useState("");
    useEffect(() => {
        setSub1(user?.sub1 ?? window.localStorage.getItem('sub1') ?? "clubcasino")
    }, [user]);
    useEffect(() => {
        if (sub1 === "brat") {
            setLinkTg("https://t.me/zolotoy_baks");
        } else if (sub1 === "alex") {
            setLinkTg("https://t.me/himars_777");
        } else if (sub1 === "tts") {
            setLinkTg("https://t.me/slotix777");
        } else if (sub1 === "tti") {
            setLinkTg("https://t.me/luckycashh");
        }else if (sub1 === "mik") {
            setLinkTg("https://t.me/mikke_live");
        }
    }, [sub1]);
    return (
        <div className='card__telegram'>
            <img className='card__telegram__img' src={TelegramLogo} alt="telegram logo"/>
            <div className="card__telegram__text__container">
                <p className="card__telegram__text">telegram</p>
                <img className="card__telegram__text__icon" src={TelegramIco} alt="telegram icon"/>
            </div>
            <a style={{textDecoration: "none"}} target="_blank" href={linkTg}>
                <button className="card__telegram__btn">Перейти в канал</button>
            </a>
        </div>
    );
};

export default CardTelegram;
